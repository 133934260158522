const DEMO_URL = '/demo/';

export const getRedirectDemoLink = (productCategory, language, url) => {
  if (url && url !== DEMO_URL) return url;

  let category, demoUrl;
  if (!!productCategory && typeof productCategory !== 'string') {
    category = productCategory[0];
  } else {
    category = productCategory;
  }

  switch (category) {
    case 'Care':
      demoUrl = '/demo-care/';
      break;
    case 'Engagement':
      demoUrl = '/demo-engagement/';
      break;
    case 'Research':
      demoUrl = '/demo-research/';
      break;
    case 'Marketing & Advertising':
      demoUrl = '/demo-marketing/';
      break;
    case 'Platform':
      demoUrl = '/demo-platform/';
      break;
    default:
      demoUrl = DEMO_URL;
      break;
  }

  return !!language && language !== 'en' ? `/${language}${demoUrl}` : demoUrl;
};
